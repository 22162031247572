<template>
  <div class="Category">
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>製品カテゴリ一覧</v-toolbar-title>
      </v-toolbar>
      <p>調べたい製品のカテゴリを選択してください。</p>
      <v-list>
        <v-list-item-group>
          <v-list-item :to="{ name: 'Top' }">
            <v-list-item-content>トップに戻る</v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-card class="mx-auto" max-width="1170">
      <v-toolbar color="orange" dark>
        <v-toolbar-title>カテゴリ一覧</v-toolbar-title>
      </v-toolbar>
      <v-list two-line>
        <v-list-item-group>
          <template v-for="category in categories">
            <v-list-item :key="category.id" :to="{ name: 'Manufacturer', params: { categorySlug: category.slug } }">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-text="category.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="category.updated"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>


<script>
const axios = require('axios')

export default{
  name: 'Category',
  components: {
  },
  data () {
    return {
      categories: null
    }
  },
  mounted() {
    axios.get('https://api.pc-combo.tokyo/categories.json')
        .then(response => (this.categories = response.data.categories))
        .catch(error => console.log(error))
  }
}
</script>
